.custom-text-slightly-bolder {
  font-weight: 500;
}
.custom-card-text-subheading {
  text-transform: uppercase;
  color: $global-border;
  font-weight: 700;
}
.custom-card-text-h2 {
  color: $global-gray-7-color;
  font-weight: 600;
}
.custom-card-text-body {
  margin: 0 !important;
  padding: 0 !important;
}