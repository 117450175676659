$global-color:                  #727274;
$global-primary-background:     #8770D9;
$global-secondary-background:   #2B2D42;
$global-muted-background:       #f3f3f3;
$global-dark-background:        #2B2D42;



// 1. Custom variables pre override.
@import "theme/variables";

// 2. Import default variables and available mixins.
@import "uikit/variables-theme";
@import "uikit/mixins-theme";

// 3. Import variables overrides.
@import "theme/variables-override";

// 4. Custom mixin overwrites.
@import "theme/mixins";

// 5. Import UIkit.
@import "theme/uikit";

// Other vendor styles
@import "theme/cld";
@import "theme/gdpr-cookie-notice";