// Name:            Button
// Description:     Styles for buttons
//
// Component:       `uk-button`
//
// Sub-objects:     `uk-button-group`
//
// Modifiers:       `uk-button-default`
//                  `uk-button-primary`
//                  `uk-button-secondary`
//                  `uk-button-danger`
//                  `uk-button-text`
//                  `uk-button-link`
//                  `uk-button-small`
//                  `uk-button-large`
//
// States:          `uk-active`
//
// ========================================================================


// Variables
// ========================================================================

$button-line-height: $global-control-height !default;
$button-small-line-height: $global-control-small-height !default;
$button-large-line-height: $global-control-large-height !default;

$button-font-size: $global-font-size !default;
$button-small-font-size: $global-small-font-size !default;
$button-large-font-size: $global-medium-font-size !default;

$button-padding-horizontal: $global-gutter !default;
$button-small-padding-horizontal: $global-small-gutter !default;
$button-large-padding-horizontal: $global-medium-gutter !default;

$button-default-background: $global-inverse-color;
$button-default-color: $global-border;
$button-default-hover-background: $global-inverse-color;
$button-default-hover-color: $global-border-hover;
$button-default-active-background: $global-inverse-color;
$button-default-active-color: darken($button-primary-background, 30%);

$button-primary-background: $global-primary-background !default;
$button-primary-color: $global-inverse-color !default;
$button-primary-hover-background: darken(
  $button-primary-background,
  5%
) !default;
$button-primary-hover-color: $global-inverse-color !default;
$button-primary-active-background: darken(
  $button-primary-background,
  30%
) !default;
$button-primary-active-color: $global-inverse-color !default;

$button-secondary-background: $global-secondary-background !default;
$button-secondary-color: $global-inverse-color !default;
$button-secondary-hover-background: darken(
  $button-secondary-background,
  5%
) !default;
$button-secondary-hover-color: $global-inverse-color !default;
$button-secondary-active-background: darken(
  $button-secondary-background,
  10%
) !default;
$button-secondary-active-color: $global-inverse-color !default;

$button-danger-background: $global-danger-background !default;
$button-danger-color: $global-inverse-color !default;
$button-danger-hover-background: darken($button-danger-background, 5%) !default;
$button-danger-hover-color: $global-inverse-color !default;
$button-danger-active-background: darken(
  $button-danger-background,
  10%
) !default;
$button-danger-active-color: $global-inverse-color !default;

$button-disabled-background: $global-muted-background !default;
$button-disabled-color: $global-muted-color !default;

$button-text-line-height: $global-line-height !default;
$button-text-color: $global-muted-color !default;
$button-text-hover-color: $global-color !default;
$button-text-disabled-color: $global-muted-color !default;

$button-link-line-height: $global-line-height !default;
$button-link-color: $global-link-color !default;
$button-link-hover-color: $global-link-hover-color !default;
$button-link-hover-text-decoration: underline !default;
$button-link-disabled-color: $global-muted-color !default;

.uk-button {
  /* 1 */
  margin: 0;
  /* 2 */
  border: none;
  /* 3 */
  overflow: visible;
  /* 4 */
  font: inherit;
  color: inherit;
  /* 5 */
  text-transform: none;
  /* 6 */
  -webkit-appearance: none;
  border-radius: 0;
  /* 7 */
  display: inline-block;
  box-sizing: border-box;

  padding: $button-padding;
  vertical-align: middle;
  font-size: $button-font-size;
  text-align: center;
  /* 10 */
  text-decoration: none;
  @if (mixin-exists(hook-button)) {
    @include hook-button();
  }
}

.uk-button:not(:disabled) {
  cursor: pointer;
}

/*
* Remove the inner border and padding in Firefox.
*/

.uk-button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/* Hover */
.uk-button:hover {
  /* 9 */
  text-decoration: none;
  @if (mixin-exists(hook-button-hover)) {
    @include hook-button-hover();
  }
}

/* Focus */
.uk-button:focus {
  outline: none;
  @if (mixin-exists(hook-button-focus)) {
    @include hook-button-focus();
  }
}

/* OnClick + Active */
.uk-button:active,
.uk-button.uk-active {
  @if (mixin-exists(hook-button-active)) {
    @include hook-button-active();
  }
}

/* Style modifiers
========================================================================== */

/*
* Default
*/

.uk-button-default {
  background-color: $button-default-background;
  color: $button-default-color;
  @if (mixin-exists(hook-button-default)) {
    @include hook-button-default();
  }
}

/* Hover + Focus */
.uk-button-default:hover,
.uk-button-default:focus {
  background-color: $button-default-hover-background;
  color: $button-default-hover-color;
  @if (mixin-exists(hook-button-default-hover)) {
    @include hook-button-default-hover();
  }
}

/* OnClick + Active */
.uk-button-default:active,
.uk-button-default.uk-active {
  background-color: $button-default-active-background;
  color: $button-default-active-color;
  @if (mixin-exists(hook-button-default-active)) {
    @include hook-button-default-active();
  }
}

/*
* Primary
*/

.uk-button-primary {
  background-color: $button-primary-background;
  color: $button-primary-color;
  @if (mixin-exists(hook-button-primary)) {
    @include hook-button-primary();
  }
}

/* Hover + Focus */
.uk-button-primary:hover,
.uk-button-primary:focus {
  background-color: $button-primary-hover-background;
  color: $button-primary-hover-color;
  @if (mixin-exists(hook-button-primary-hover)) {
    @include hook-button-primary-hover();
  }
}

/* OnClick + Active */
.uk-button-primary:active,
.uk-button-primary.uk-active {
  background-color: $button-primary-active-background;
  color: $button-primary-active-color;
  @if (mixin-exists(hook-button-primary-active)) {
    @include hook-button-primary-active();
  }
}

/*
* Secondary
*/

.uk-button-secondary {
  background-color: $button-secondary-background;
  color: $button-secondary-color;
  @if (mixin-exists(hook-button-secondary)) {
    @include hook-button-secondary();
  }
}

/* Hover + Focus */
.uk-button-secondary:hover,
.uk-button-secondary:focus {
  background-color: $button-secondary-hover-background;
  color: $button-secondary-hover-color;
  @if (mixin-exists(hook-button-secondary-hover)) {
    @include hook-button-secondary-hover();
  }
}

/* OnClick + Active */
.uk-button-secondary:active,
.uk-button-secondary.uk-active {
  background-color: $button-secondary-active-background;
  color: $button-secondary-active-color;
  @if (mixin-exists(hook-button-secondary-active)) {
    @include hook-button-secondary-active();
  }
}

/*
* Danger
*/

.uk-button-danger {
  background-color: $button-danger-background;
  color: $button-danger-color;
  @if (mixin-exists(hook-button-danger)) {
    @include hook-button-danger();
  }
}

/* Hover + Focus */
.uk-button-danger:hover,
.uk-button-danger:focus {
  background-color: $button-danger-hover-background;
  color: $button-danger-hover-color;
  @if (mixin-exists(hook-button-danger-hover)) {
    @include hook-button-danger-hover();
  }
}

/* OnClick + Active */
.uk-button-danger:active,
.uk-button-danger.uk-active {
  background-color: $button-danger-active-background;
  color: $button-danger-active-color;
  @if (mixin-exists(hook-button-danger-active)) {
    @include hook-button-danger-active();
  }
}

/*
* Disabled
* The same for all style modifiers
*/

.uk-button-default:disabled,
.uk-button-primary:disabled,
.uk-button-secondary:disabled,
.uk-button-danger:disabled {
  background-color: $button-disabled-background;
  color: $button-disabled-color;
  @if (mixin-exists(hook-button-disabled)) {
    @include hook-button-disabled();
  }
}

/* Size modifiers
========================================================================== */

.uk-button-small {
  padding: 0 $button-small-padding-horizontal;
  line-height: $button-small-line-height;
  font-size: $button-small-font-size;
  @if (mixin-exists(hook-button-small)) {
    @include hook-button-small();
  }
}

.uk-button-large {
  padding: 0 $button-large-padding-horizontal;
  line-height: $button-large-line-height;
  font-size: $button-large-font-size;
  @if (mixin-exists(hook-button-large)) {
    @include hook-button-large();
  }
}

/* Text modifiers
========================================================================== */

/*
* Text
* 1. Reset
* 2. Style
*/

.uk-button-text {
  /* 1 */
  padding: 0;
  line-height: $button-text-line-height;
  background: none;
  /* 2 */
  color: $button-text-color;
  @if (mixin-exists(hook-button-text)) {
    @include hook-button-text();
  }
}

/* Hover + Focus */
.uk-button-text:hover,
.uk-button-text:focus {
  color: $button-text-hover-color;
  @if (mixin-exists(hook-button-text-hover)) {
    @include hook-button-text-hover();
  }
}

/* Disabled */
.uk-button-text:disabled {
  color: $button-text-disabled-color;
  @if (mixin-exists(hook-button-text-disabled)) {
    @include hook-button-text-disabled();
  }
}

/*
* Link
* 1. Reset
* 2. Style
*/

.uk-button-link {
  /* 1 */
  padding: 0;
  line-height: $button-link-line-height;
  background: none;
  /* 2 */
  color: $button-link-color;
  @if (mixin-exists(hook-button-link)) {
    @include hook-button-link();
  }
}

/* Hover + Focus */
.uk-button-link:hover,
.uk-button-link:focus {
  color: $button-link-hover-color;
  text-decoration: $button-link-hover-text-decoration;
}

/* Disabled */
.uk-button-link:disabled {
  color: $button-link-disabled-color;
  text-decoration: none;
}

/* Group
========================================================================== */

/*
* 1. Using `flex` instead of `inline-block` to prevent whitespace betweent child elements
* 2. Behave like button
* 3. Create position context
*/

.uk-button-group {
  /* 1 */
  display: inline-flex;
  /* 2 */
  vertical-align: middle;
  /* 3 */
  position: relative;
}
