@mixin hook-base-body() {
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&family=Source+Sans+Pro:wght@400;700');
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;

  hr {
    margin: $global-large-margin 0;
    border-top: $base-hr-border-width solid $global-inverse-2-color;
  }
}

@mixin hook-article() {
  line-height: 1.6;

  > h1 {
    font-size: 2.25rem;
  }

  h2 {
    font-size: 1.875rem;
  }

  h3 {
    font-size: 1.5rem;
    font-weight: 400;
    border-bottom: solid 1px #e5e5e5;
    line-height: 1.6;
  }

  table {
    font-size: 0.875rem;
    table-layout: fixed;
    border-collapse: separate;
    border-spacing: 0px 0px;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    width: 100%;
  
    *, :after, :before {
      border: 0 solid #d9d9e3;
      box-sizing: border-box;
    }
  
    th {
      background-color: rgba(236,236,241,.2);
      border-bottom-width: 1px;
      border-left-width: 1px;
      border-top-width: 1px;
      padding: 0.25rem 0.75rem;
      text-align: left;
      color: $global-black-color;
      
      &:first-child {
        border-top-left-radius: 0.375rem;
      }

      &:last-child {
        border-right-width: 1px;
        border-top-right-radius: 0.375rem;
      }
    }
  
    tbody {
      td {
        border-bottom-width: 1px;
        border-left-width: 1px;
        padding: 0.25rem 0.75rem;

        &:last-child {
          border-right-width: 1px;
        }
      }

      tr:last-child {
        td {
          &:first-child {
            border-bottom-left-radius: 0.375rem;
          }
          &:last-child { 
            border-bottom-right-radius: 0.375rem;
          }
        }
      }
    }
  }

  blockquote,
  img {
    margin: 40px 0;
  }

  blockquote {
    padding: 25px 30px;
    border-left: solid 5px #f0ad4e;
    background-color: #fcf8f2;
  }

  ul,
  ol {
    margin: 20px 0;
  }

  li,
  ul > li > ul,
  ul > li > ol,
  ol > li > ol,
  ol > li > ul {
    margin-top: 1px;
    margin-bottom: 1px;

    table {
      margin-top: 1rem;
      margin-bottom: 1.5rem;

      td {
          vertical-align: top !important;
          font-size: 0.85rem;
      }
    }
  }

  * + h1, * + .uk-h1,
  * + h2, * + .uk-h2,
  * + h3, * + .uk-h3,
  * + h4, * + .uk-h4,
  * + h5 {
    margin-top: 60px;
  }

  .outdent {
    > li::marker {
      font-size: 1.875rem;
    }

    > li > h2 {
      margin-top: 30px;
    }
  }

  @media (min-width: $breakpoint-medium) {
    img {
      left: 50%;
      margin-left: -75px;
      margin-right: -75px;
      max-width: 820px;
      right: 50%;
    }

    table td img {
      margin: auto;
      right: auto;
    }
  }

  @media (min-width: $breakpoint-large) {
    img {
      left: 50%;
      margin-left: -125px;
      margin-right: -125px;
      right: 50%;
    }

    table td img {
      margin: auto;
      right: auto;
    }
  }
}

@mixin hook-table-misc() {
}

@mixin hook-button() {
  border-radius: 4px;
}

@mixin hook-button-link() {
  font-weight: $base-strong-font-weight;
  font-size: 1rem;
  color: $global-secondary-background;
  letter-spacing: 0.02em;

  &::after {
    content: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 38 13'%3E%3Cpath fill='%23000' d='M37.53 7.03a.75.75 0 0 0 0-1.06l-4.773-4.773a.75.75 0 0 0-1.06 1.06L35.939 6.5l-4.242 4.243a.75.75 0 0 0 1.06 1.06L37.53 7.03zM0 7.25h37v-1.5H0v1.5z'/%3E%3C/svg%3E");
    display: inline-block;
    margin-left: 8px;
    vertical-align: middle;
    width: 36px;
  }

  &:hover::after,
  &:focus::after {
    color: $button-link-hover-color;
  }
}

@mixin hook-button-misc() {
  .uk-button-link-primary {
    padding: 0;
    line-height: $button-link-line-height;
    background: none;
    color: $global-primary-background;
    font-weight: $base-strong-font-weight;
  }

  .uk-button-link-primary:hover,
  .uk-button-link-primary:focus {
    color: $global-secondary-background;
  }

  .uk-button-dark {
    background: $global-dark-background;
    color: $global-inverse-color;
    border: 1px solid $global-dark-background;
  }

  .uk-button-dark:hover,
  .uk-button-dark:focus {
    color: $global-inverse-color;
    background: lighten($global-dark-background, 10%);
  }
}

@mixin hook-nav-primary() {
  &.uk-nav-parent-icon > .uk-parent:not(.uk-open) > a:after {
    transform: rotate(-90deg);
  }
  .uk-nav-sub a {
    font-size: 1.5rem;
    padding: 8px 0;
  }
}

@mixin hzook-navbar-misc() {
  /*.uk-navbar-dropbar {
    border-top: solid 1px $global-border;
  }*/

  .uk-navbar-dropdown-dropbar {
    padding-left: 11px;
    background-color: transparent;
  }
}

@mixin hook-navbar-nav-item() {
  letter-spacing: 0.02em;

  // &:hover {
  //   &:before {
  //     content: "";
  //     border-bottom: solid 2px $global-primary-background;
  //     position: absolute;
  //     bottom: 15px;
  //     left: 7px;
  //     right: 9px;
  //   }
  // }

  &:focus {
    i {
      border-color: $base-link-color;
    }
  }
}

@mixin hook-navbar-nav-item-active() {
  position: relative;
  &:before {
    content: '';
    border-bottom: solid 2px $global-primary-background;
    position: absolute;
    bottom: 15px;
    left: 9px;
    right: 10px;
  }
  &.uk-dropdown-parent-link:before {
    left: 7px;
    right: 9px;
  }
  i {
    border-color: $base-link-color;
  }
}

@mixin hook-subnav-pill-item() {
  border-radius: 4px;
  border: solid 1px darken($global-muted-background, 7%);
}

@mixin hook-subnav-pill-item-active() {
  border-color: $global-primary-background;
}

@mixin hook-utility-misc() {
  
  #tally-assessment-banner
  {
    #tally-show-popup {
      color: $global-border-hover !important;
      text-decoration: none;
  
      &:hover {
        text-decoration: underline;
      }
    }

    .text-light-grey {
      color: #dadada !important;
    }
  }

  #tally-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.75);
    transition: opacity .25s ease-in-out;
    z-index: 10001;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    visibility: collapse;

    .iframe-wrapper {
      position: relative;
      transition: opacity .25s ease-in-out;
      min-width: 360px;
      min-height: 360px;
      opacity: 1;
      width: calc(100% - 80px);
      height: calc(100% - 100px);
        
      iframe {
        width: 100%;
        height: 100%;
        border: none;
        overflow: auto;
        border-radius: 8px;
      }
      
      #tally-close-popup {
        color: #fff !important;
        display: block;
        padding: 0;
        margin: 0;
        position: absolute;
        font-size: 32px;
        font-weight: normal;
        line-height: 24px;
        width: 24px;
        height: 24px;
        text-align: center;
        text-transform: none;
        cursor: pointer;
        opacity: .75;
        transition: opacity .25s ease-in-out;
        text-decoration: none;
        color: #000;
        top: -34px;
        right: 0;
        background: none;
        border: none;
        border-radius: 0;

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  .uk-card-blog {
    background: none;
    box-shadow: none;

    .uk-card-footer {
      padding: 8px 0 0 0;
    }

    .uk-card-body {
      padding: 6px 0 30px 0;
    }

    .uk-card-media-top {
      margin-top: 20px;
    }

    @media (min-width: $breakpoint-large) {
      .uk-card-body {
        padding: 6px 0 30px 0;
      }

      .uk-card-footer {
        padding: 10px 0 0 0;
      }
    }

    .uk-card-footer {
      border: none;
    }

    .uk-card-media-top {
      img {
        border-radius: 10px;
        box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 15px;
      }
    }
  }

  .uk-text-spacing {
    letter-spacing: 0.04em;
  }

  .uk-text-spacing-medium {
    letter-spacing: 0.06em;
  }

  .uk-title-negative {
    margin-left: -3px;
  }

  .uk-line-height-7 {
    line-height: 1.75;
  }

  .uk-line-height-8 {
    line-height: 1.85;
  }

  .uk-text-light {
    color: #777;
  }

  .uk-text-larger {
    font-size: 1.125rem;
    line-height: 1.75;
  }

  .uk-text-smaller {
    font-size: 0.9rem;
  }

  .uk-margin-m-top {
    margin-top: 30px;
  }

  .uk-margin-xxsmall-top {
    margin-top: 4px;
  }

  .uk-margin-m-bottom {
    margin-bottom: 30px;
  }

  .uk-margin-m {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .uk-border-bottom.uk-sticky.uk-sticky-fixed {
    z-index: 995;
  }

  .uk-border-bottom {
    border-bottom: solid $global-border-width $global-border;
  }

  .uk-border {
    border: solid $global-border-width $global-border;
  }

  .uk-padding-medium {
    padding: 30px;
  }

  .uk-logo {
    padding-left: 0;
  }

  @media (min-width: $breakpoint-large) {
    .uk-width-60\@l {
      width: 60%;
    }
  }

  @media (min-width: $breakpoint-medium) {
    .uk-flex-middle-mobile {
      align-items: center;
    }
  }

  .uk-address-box {
    padding: 2rem;
    border-radius: 8px 0 8px 0;

    position: relative;
    overflow: hidden;

    &::after {
      content: '';
      position: absolute;
      background-color: $global-border;
      height: 8px;
      width: 105px;
      border-radius: 4px;
      bottom: 0;
      left: -10px;
      display: block;
    }
  }

  .uk-team-section {
    & > div:first-child {
      margin-bottom: 6rem;
    }
  }

  .uk-team-card {
    max-width: 260px;

    &-container {
      display: flex;
      justify-content: center;
      align-items: stretch;
      flex-flow: row wrap;
      gap: 3rem;
    }

    &-top {
      img {
        width: 100%;
        aspect-ratio: 1;
      }
    }

    &-bottom {
      padding: 2rem;
      padding-bottom: calc(2rem + 8px);
      text-align: left;

      h4 {
        margin: 0;
      }
    }

    box-shadow: 0px 2px 12px 2px rgba(29, 30, 31, 0.1);
    border-radius: 8px 0 8px 0;
    margin: 0;
    padding: 0;
    width: fit-content;

    position: relative;
    overflow: hidden;

    &::after {
      content: '';
      position: absolute;
      background-color: $global-border;
      height: 8px;
      width: 105px;
      border-radius: 4px;
      bottom: 0;
      left: -10px;
      display: block;
    }
  }

  .uk-section-default + .uk-section-default,
  .uk-section-muted + .uk-section-muted,
  .uk-section-secondary + .uk-section-secondary,
  .uk-section-dark + .uk-section-dark,
  .uk-section-primary + .uk-section-primary {
    padding-top: 0;
  }

  // Networking block
  .uk-networking-box {
    // margin-top: $margin-large-margin;

    // &:before, &:after {
    //   border: solid transparent;
    //   bottom: 100%;
    //   content: "";
    //   height: 0;
    //   left: 50%;
    //   pointer-events: none;
    //   position: absolute;
    //   width: 0;
    // }

    // &:before {
    //   border-bottom-color: $global-border;
    //   border-width: 18px;
    //   margin-left: -18px;
    // }

    // &:after {
    //   border-bottom-color: white;
    //   border-width: 17px;
    //   margin-left: -17px;
    // }
  }

  .uk-networking-active {
    border-color: $global-primary-background;
    background-color: lighten($global-primary-background, 62%);
  }

  // .uk-networking-box.uk-networking-active {
  //   &:before {
  //     border-bottom-color: $global-primary-background;
  //   }

  //   &:after {
  //     border-bottom-color: lighten($global-primary-background,62%);
  //   }
  // }

  .uk-networking-box-heading {
    margin-bottom: 28px;
  }

  // @media (min-width: $breakpoint-medium) {
  // .uk-networking-box {
  //   margin-top: $margin-large-margin-l + 55px;
  // }
  // }

  // @media (max-width: $breakpoint-medium) {
  //   .uk-networking-mobile {
  //     margin-left: 10px;
  //   }
  // }

  // .uk-networking-line {
  //   position: absolute;
  //   left: 0;
  //   right: auto;
  //   top: 0;
  //   width: auto;

  //   svg {
  //     left: -41px;
  //     top: 40px;
  //     position: relative;
  //   }

  // }

  // @media (min-width: $breakpoint-medium) {
  .uk-networking-line {
    position: absolute;
    right: 0;
    top: -75px;
    left: 0;
    width: 100%;

    svg {
      left: 50%;
      margin-left: -12px;
      top: -1px;
      position: relative;
    }
  }
  // }

  // .uk-networking-line span {
  //     display: block;
  //     height: 1px;
  //     position: absolute;
  //     top: 12px;
  //     width: 100%;
  // }

  // .uk-networking-icon-1 {
  //   path {
  //     stroke: $global-primary-background;
  //   }

  //   circle {
  //     fill: $global-primary-background;
  //   }
  // }

  // .uk-networking-icon-2 {
  //   path:first-child {
  //     stroke: $global-primary-background;
  //     fill: $global-primary-background;
  //   }
  // }

  // @media (max-width: $breakpoint-medium) {
  //   .uk-networking-line span:first-child {
  //     background-image:linear-gradient(180deg,rgba(210,212,219,0) 0,darken($global-border, 10%) 100%);
  //     height: 283px;
  //     right: auto;
  //     left: -29px;
  //     top: -253px;
  //     width: 1px
  //   }
  // }

  // @media (min-width: $breakpoint-medium) {
  //   .uk-networking-line span:first-child {
  //     background-image: linear-gradient(90deg,rgba(210,212,219,0) 0, darken($global-border, 10%) 100%);
  //     right: calc(50% + 20px);
  //     width: calc(50% + 140px);
  //   }
  // }

  // @media (min-width: $breakpoint-medium) {
  //   .uk-networking-line span:last-child {
  //       background-image: linear-gradient(90deg,darken($global-border, 10%) 0,$global-primary-background 100%);
  //       left: calc(50% + 20px)
  //   }
  // }

  // @media (max-width: $breakpoint-medium) {
  //   .uk-networking-line span:last-child {
  //     background-image:linear-gradient(180deg,darken($global-border, 10%) 0,$global-primary-background 100%);
  //     height: calc(100% + 740px);
  //     left: -29px;
  //     top: 68px;
  //     width: 1px;
  //   }
  // }

  // .uk-networking-line span:last-child:after {
  //   border-color: transparent transparent transparent $global-primary-background;
  //   border-style: solid;
  //   border-width: 5.5px 0 5.5px 8px;
  //   content: "";
  //   height: 0;
  //   position: absolute;
  //   right: -8px;
  //   top: -5px;
  //   width: 0
  // }

  // @media (max-width: $breakpoint-medium) {
  //   .uk-networking-line span:last-child:after {
  //     bottom:-8px;
  //     right: -4px;
  //     top: auto;
  //     transform: rotate(90deg)
  //   }
  // }

  .uk-h5-roadmap {
    font-family: $global-font-family;
    font-weight: 600;
    font-size: 0.8em;
  }

  .uk-label-roadmap {
    font-size: 0.75rem;
    text-transform: none;
    border-radius: 3px;
    padding: 3px 6px;
  }

  .uk-grid-divider > :not(.uk-first-column)::before {
    border-left: 1px dashed #dcdcdc;
  }

  .uk-label-feature {
    background: #f8e18a;
  }

  .uk-label-engagement {
    background: #a7daff;
  }

  .uk-label-delight {
    background: #b0eacd;
  }

  .uk-label-advanced-usage {
    background: #d39aff;
  }

  .uk-label-integrations {
    background: #d5f584;
  }

  .uk-label-enterprise {
    background: #f5a0a1;
  }

  .uk-subheading {
    font-size: 1.3rem;
    margin-top: 8px;
  }

  .uk-card-feature {
    min-height: 270px;

    padding: 2rem;
    border-radius: 8px 0 8px 0;
    box-shadow: 0px 2px 12px 2px rgba(29, 30, 31, 0.1);
    overflow: hidden;
    position: relative;

    h3 {
      margin-top: 12px;
      font-family: $global-font-family;
      font-size: 1.2em;
      font-weight: bold;
      text-align: left;
    }

    p {
      text-align: left;
      color: $global-gray-4-color;
    }

    p:last-child {
      margin-bottom: 0;
    }

    &::after {
      background-color: $global-border;
      position: absolute;
      content: '';
      height: 8px;
      width: 105px;
      border-radius: 4px;
      bottom: 0;
      left: -10px;
    }
  }

  .uk-features-comparison {
    &-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      gap: 2rem;
    }

    &-title,
    &-item {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 4rem;

      & > * {
        width: 50%;
      }
    }
  }

  // Careers
  .uk-container-careers {
    max-width: 950px;
  }
  .uk-text-xsmall {
    font-size: 0.75rem;
  }
  .uk-text-xxsmall {
    font-size: 0.6875rem;
  }
  .uk-font-roboto {
    font-family: $global-font-family;
  }
  .uk-link-read-more {
    color: inherit;
    &:hover {
      color: $global-primary-background;
      text-decoration: none;
    }
  }
  .caption {
    font-style: italic;
    font-size: 0.75rem;
    color: $global-muted-color;
    text-align: center;
    margin-bottom: 40px;
    margin-top: -50px;
  }
  .uk-career-sidebar {
    font-size: 0.875rem;
    > div + div {
      margin-top: 30px;
    }
    li:not(:first-child) {
      margin-top: 8px;
    }
    // p+p {
    //   margin-top: 40px;
    // }
    p:last-child,
    ul:last-child {
      margin-bottom: 0;
    }
    > div {
      padding: 35px 30px 33px;
      border-radius: 5px;
      h6 {
        color: $global-gray-7-color;
        font-size: 0.75rem;
      }
    }
  }
  .uk-padding-apply-button {
    padding: 30px;
    p:last-child {
      margin-bottom: 0;
    }
  }
  .uk-job-card {
    border: 1px solid rgb(235, 235, 235);
    padding: 20px 20px;
    &:hover {
      background-color: $global-inverse-1-color;
    }
    &:hover .uk-text-muted,
    &:hover h5 {
      color: $global-primary-background !important;
    }

    @media (max-width: $breakpoint-medium) {
      h5 {
        margin-bottom: 10px !important;
      }
    }
  }
  .uk-career-sidebar + .uk-career-sidebar {
    margin-top: 50px;
  }
  .uk-margin-departments ~ .uk-margin-departments {
    margin-top: 90px;
  }
  .uk-icon-list {
    margin-left: 30px;
    gap: 4rem;

    li {
      position: relative;

      &:before {
        height: 20px;
        margin-top: 1px;
        width: 20px;
        background-repeat: no-repeat;
        content: '';
        left: -30px;
        position: absolute;
      }
    }
  }

  #modal-apply .uk-close-large {
    top: 20px;
    right: 15px;
  }

  .uk-gear li:before {
    background-image: url("data:image/svg+xml,%3Csvg class='w-6 h-6' fill='none' stroke='#{$global-primary-background-data}' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='1.7' d='M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z'%3E%3C/path%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M15 12a3 3 0 11-6 0 3 3 0 016 0z'%3E%3C/path%3E%3C/svg%3E");
    height: 24px;
    margin-top: -1px;
    width: 24px;
  }

  .uk-check-circle li:before {
    background-image: url(../../uploads/check-circle-icon.svg);
    background-size: cover;
    background-repeat: no-repeat;
    height: 1em;
    margin-top: 3px;
    width: 1em;
  }

  .uk-check-border li:before {
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%0A%3E%3Cpath d='M10.2426 16.3137L6 12.071L7.41421 10.6568L10.2426 13.4853L15.8995 7.8284L17.3137 9.24262L10.2426 16.3137Z' fill='#{$global-primary-background-data}' /%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1 5C1 2.79086 2.79086 1 5 1H19C21.2091 1 23 2.79086 23 5V19C23 21.2091 21.2091 23 19 23H5C2.79086 23 1 21.2091 1 19V5ZM5 3H19C20.1046 3 21 3.89543 21 5V19C21 20.1046 20.1046 21 19 21H5C3.89543 21 3 20.1046 3 19V5C3 3.89543 3.89543 3 5 3Z' fill='#{$global-primary-background-data}' /%3E%3C/svg%3E");
  }

  .uk-check li:before {
    background-image: url(../img/check.svg);
  }

  .uk-cross li:before {
    background-image: url(../img/close.svg);
  }

  .uk-icon-challenge,
  .uk-icon-benefit {
    margin-left: 40px;
    position: relative;
    &:before {
      width: 30px;
      height: 30px;
      content: '';
      position: absolute;
      background-repeat: no-repeat;
      left: -40px;
      top: 2px;
    }
  }

  .uk-icon-challenge:before {
    background-image: url(../img/challenge.svg);
  }

  .uk-icon-benefit:before {
    background-image: url(../img/benefit.svg);
  }

  // Use cases block
  .uk-usecase-card-body {
    border: solid $global-border-width $global-gray-2-color;
    padding: 5px;
  }

  .uk-usecase-card-footer {
    border: solid $global-border-width $global-border;
    border-top: none;
    padding: $padding-small-padding 0 $padding-small-padding 0;
    background-color: $global-inverse-1-color;
    a:hover {
      text-decoration: none;
      color: $global-dark-background;
    }
  }

  .uk-solution-use-case {
    font-size: 0.9rem;
    h3 {
      font-size: 1.3rem;
      padding: 0 20px;
      color: $base-link-color;
    }
    .uk-subtitle {
      padding: 15px 20px;
    }
  }

  // Card block
  @media (min-width: $breakpoint-medium) {
    .uk-card-block .uk-card-body {
      margin-left: calc($padding-large-padding-l - 1rem);
      margin-right: calc($padding-large-padding-l - 1rem);
    }

    .uk-card-block .uk-card-media-right + div > .uk-card-body {
      margin-left: 0;
    }

    .uk-card-block .uk-card-media-left + div > .uk-card-body {
      margin-right: 0;
    }
  }

  // Pricing block
  .uk-subscription-time {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 1.5rem 0 !important;

    @media (max-width: $breakpoint-small) {
      flex-direction: column;
      p {
        text-align: center;
      }
    }

    p {
      margin: 0;
      color: $global-emphasis-color;

      span {
        color: $global-gray-4-color;
        font-size: 0.875em;
        white-space: nowrap;
      }
    }

    .switch {
      position: relative;
      display: inline-block;
      width: 48px;
      height: 24px;
      margin: 0 1rem;

      @media (max-width: $breakpoint-small) {
        transform: rotate(90deg);
        margin: 1rem;
      }

      input {
        opacity: 0;
        width: 0;
        height: 0;
      }

      input:checked + .switch-slider::before {
        transform: translateX(24px);
      }

      &-slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $global-border;
        -webkit-transition: 0.4s;
        transition: 0.4s;

        height: 100%;
        width: 100%;
        border-radius: 34px;
      }

      &-slider:before {
        position: absolute;
        content: '';
        height: 18px;
        width: 18px;
        left: 3px;
        bottom: 3px;
        background-color: $global-inverse-color;
        transition: 0.4s;
        border-radius: 50%;
      }
    }
  }

  .uk-plan {
    margin: 0 10px 20px 10px;

    hr {
      border-color: $global-inverse-2-color;
      margin-top: 25px;
      margin-bottom: 35px;
    }
    .uk-card-title {
      font-size: 1.75rem;
    }
    .uk-button {
      line-height: 40px;
      width: 100%;
    }

    .uk-plan-title {
      font-size: 1.25rem;
      margin: 0;
    }

    .uk-plan-price-block {
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      margin: 1rem 0;

      .uk-price-title {
        margin: 0.75rem 0.5rem 0 0;
        font-size: 3rem;
        line-height: 1rem;

        &-margin-bottom {
          margin: 0;
          line-height: 2.5rem;
          white-space: nowrap;
        }
      }
      .uk-price-note {
        font-size: 0.75rem;
        font-weight: normal;
        line-height: 0.75rem;
        white-space: nowrap;
        color: $global-gray-4-color;
      }
    }

    .uk-plan-description {
      margin-bottom: 1rem;
    }

    &.uk-plan-underline {
      position: relative;
      overflow: hidden;

      &::after {
        content: '';
        position: absolute;
        background-color: $global-border;
        height: 8px;
        width: 105px;
        border-radius: 4px;
        bottom: 0;
        left: -10px;
        display: block;
      }
    }
  }

  .uk-plan-comparison-table {
    > div:not(:last-child) {
      border-bottom: 1px solid $global-gray-2-color;
    }

    &-heading {
      border-bottom: 1px solid $global-gray-2-color;
    }

    &-category-title {
      font-size: 1.25rem;
      margin-top: 3rem;
      margin-bottom: 1rem;
    }

    &-row {
      &:not(:first-child) {
        border-top: 1px solid #dbdbdc;
      }
    }

    > div {
      padding-left: 1rem;
    }
  }

  .uk-pricing-label {
    position: absolute;
    top: -13px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    .uk-label {
      font-size: 13px;
    }
  }
  .uk-price {
    font-size: 2.5rem;
    line-height: 1;
  }
  .uk-price-container {
    background: #f5f5f5;
    min-height: 185px;
    margin: 35px auto;
    border-radius: 3px;
  }
  .uk-select:not([multiple]):not([size]) {
    padding-right: 8px;
    padding-left: 8px;
    height: 33px;
    -webkit-appearance: revert;
    -moz-appearance: revert;
    background-image: revert;
  }
  .price-select {
    font-size: 13px;
    &:focus {
      border-color: $global-border;
    }
  }
  .uk-price-text {
    font-size: 1.5rem;
  }
  .uk-currency {
    vertical-align: top;
  }

  .uk-pricing-table {
    font-size: 0.9375rem;

    .uk-grid-small > *,
    .uk-grid-column-small > * {
      padding-left: 20px;
    }

    .uk-button {
      line-height: 40px;
      width: 100%;
    }

    @media (min-width: $breakpoint-large) {
      .uk-button {
        width: 80%;
      }
    }

    .uk-accordion-title {
      font-size: 0.9375rem;
      position: relative;
      color: $global-gray-7-color;
      position: relative;
      margin-left: 15px;
      overflow: unset;

      &:before {
        margin-left: 0;
        left: -20px;
        top: 7px;
        width: 0.6em;
        height: 0.6em;
        position: absolute;
      }
    }

    .uk-accordion-content {
      margin-top: 8px;
      margin-left: 14px;
    }
    .uk-pricing-sticky {
      h3 {
        font-size: 1.5rem;
      }
    }
    h4 {
      font-size: 1.125rem;
    }
  }
  .uk-table-mobile {
    > :nth-child(n + 2),
    .uk-list > * > ul {
      margin-top: 2px;
    }

    > li:nth-child(2n + 3) {
      margin-top: 22px;
    }
  }

  .uk-z-index-negative {
    z-index: -1;
  }

  #overlayCanvas {
    min-height: 357px;
    min-width: 577px;
    width: 100%;
    padding: 0;
  }

  // Resources
  .uk-resource-icon {
    position: relative;
    top: -1px;
    margin-right: 5px;
  }

  .uk-section-secondary,
  .uk-section-primary,
  .uk-section-dark {
    .uk-navbar-dropdown-nav li a {
      color: $navbar-dropdown-nav-item-color !important;

      &:hover {
        color: $navbar-dropdown-nav-item-hover-color !important;
      }
    }

    .uk-navbar-nav > li a svg {
      path {
        stroke: $inverse-global-color;
      }
    }
  }

  .uk-section-dark {
    .uk-navbar-nav > li > a:hover {
      color: $inverse-global-color !important;
    }
  }

  .uk-section-dark,
  .uk-background-dark {
    color: $inverse-global-color !important;
    background-color: $global-dark-background;

    .uk-text-larger,
    .uk-text-large,
    .uk-text-lead,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $inverse-global-color !important;
    }

    .uk-link,
    a {
      color: $inverse-global-color !important;

      &:hover {
        color: $global-border-hover !important;
      }

      &:active {
        color: $global-border !important;
      }
    }

    .uk-button-default {
      color: $inverse-global-color !important;

      &:hover,
      &:active {
        color: $global-dark-background !important;
        background-color: $inverse-global-color;
      }
    }

    .uk-button-default:active,
    .uk-button-default:hover {
      border-color: $inverse-global-color;
    }
  }

  .uk-list-small {
    & > :nth-child(n + 2) {
      margin-top: 5px;
    }
  }

  .uk-subnav {
    margin: 0 auto;

    li.uk-active {
      border-bottom: none;
      font-weight: bold;
    }
  }

  @media (max-width: $breakpoint-medium) {
    .uk-subnav {
      li {
        a {
          padding: 10px 30px 8px 30px;
        }
      }
    }
    .uk-section-dark .erdf {
      display: none;
    }
  }

  video {
    border: 1px solid $global-inverse-2-color;
  }

  footer .logo {
    display: block;
    img {
      margin-top: -5px;
    }
  }

  footer address {
    margin-top: 11px;
  }

  @media (max-width: $breakpoint-medium) {
    footer {
      .uk-nav > li > a {
        padding: 14px 0;
      }
    }
  }

  .footer-copyright {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap-reverse;

    p {
      margin-right: 2rem;
    }
  }

  .footer-social-links {
    display: flex;
    list-style: none;
    padding: unset;

    li {
      display: flex;
      margin-left: 1rem;
      justify-content: center;
      align-items: center;

      a {
        mask-position: center;
        -webkit-mask-position: center;
        mask-repeat: no-repeat;
        -webkit-mask-repeat: no-repeat;
        mask-size: contain;
        -webkit-mask-size: contain;

        background-color: $inverse-global-color !important;
        &:hover {
          background-color: $global-border-hover !important;
        }
        &:active {
          background-color: $global-border !important;
        }
      }

      .social-link-linkedin {
        width: 21px;
        height: 21px;
        -webkit-mask: url(../../uploads/socials/linkedin.svg);
        mask: url(../../uploads/socials/linkedin.svg);
      }
      .social-link-github {
        width: 21px;
        height: 21px;
        -webkit-mask: url(../../uploads/socials/github.svg);
        mask: url(../../uploads/socials/github.svg);
      }
      .social-link-youtube {
        width: 30px;
        height: 21px;
        -webkit-mask: url(../../uploads/socials/youtube.svg);
        mask: url(../../uploads/socials/youtube.svg);
      }
      .social-link-twitter {
        width: 24px;
        height: 20px;
        -webkit-mask: url(../../uploads/socials/twitter.svg);
        mask: url(../../uploads/socials/twitter.svg);
      }
    }
  }

  footer {
    position: relative;
    z-index: 0;
    overflow: hidden;

    & > * {
      z-index: 2;
    }
  }

  footer::before {
    position: absolute;
    content: '';
    width: 45%;
    max-width: 1050px;
    aspect-ratio: 0.9;
    background-image: url(../../uploads/footer-logo.svg);
    background-repeat: no-repeat;
    background-size: cover;
    transform: rotate(-15deg);
    left: 10%;
    z-index: -1;
    opacity: 0.2;
  }

  @media (max-width: $breakpoint-medium) {
    footer::before {
      height: 60%;
      width: auto;
      min-width: 350px;
      aspect-ratio: 1;

      right: 40%;
      left: unset;
      bottom: -5%;
    }
  }

  @media (max-width: $breakpoint-small) {
    footer::before {
      right: 20%;
    }
  }

  footer::after {
    position: absolute;
    content: '';
    width: 22%;
    min-width: 200px;
    max-width: 350px;
    aspect-ratio: 0.9;
    background-image: url(../../uploads/footer-logo.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: center;
    background-position-y: bottom;
    right: -20px;
    top: -20px;
    transform: rotate(-10deg);
    z-index: -1;
    opacity: 0.2;
  }

  @media (max-width: $breakpoint-medium) {
    footer::after {
      width: 40%;
    }
  }

  .uk-width-address-contacts {
    width: 90px;
  }

  .uk-navbar-nav li {
    position: relative;
  }

  .uk-navbar-nav li a.uk-open svg {
    transform: rotate(-90deg);
  }

  .uk-navbar-nav > li > a svg {
    margin-left: 4px;
    width: 16px;
    height: 16px;
    transition: transform 0.3s;
    transform: rotate(0deg);
    transform-origin: center center;
  }

  .uk-navbar-nav > li > a {
    i {
      display: inline-block;
      border: solid $navbar-nav-item-color;
      border-width: 0 2px 2px 0;
      padding: 2px;
      margin-left: 6px;
      transition: all 0.2s ease;
      transform: rotate(45deg);
    }

    &[aria-expanded='true'] i {
      transform: rotate(-45deg);
      border-color: $base-link-color;
    }
    &:hover i {
      border-color: $base-link-color;
    }
  }

  .uk-navbar-dropdown-nav > li > a {
    i {
      display: inline-block;
      border: solid $navbar-nav-item-color;
      border-width: 0 2px 2px 0;
      padding: 2px;
      transform: rotate(45deg);
      margin-left: 5px;
      transition: all 0.2s ease;
      top: -1px;
      position: relative;
    }
    &:hover i {
      margin-left: 10px;
      border-color: $base-link-color;
    }
  }
  .uk-navbar-dropdown-nav > .uk-active > a i {
    border-color: $base-link-color;
  }

  .uk-navbar-z-index {
    z-index: 990;
  }

  .toc-list {
    list-style: none;
    font-size: 0.85rem;

    li {
      margin: 8px 0;

      &:last-child {
        margin-bottom: 4px;
      }
    }

    .toc-list-item {
      a:hover {
        text-decoration: none;
      }
    }

    .toc-link::before {
      //background-color: $global-inverse-color; // comment this line to shade the unselected items toc bar
      width: 3px;
      margin-top: -4px;
    }

    .is-active-link {
      color: $global-border;

      &::before {
        background-color: $global-border-hover;
      }
    }
  }

  .uk-post-excerpt {
    margin-top: 18px;
  }

  .uk-article {
    img {
      border-radius: 5px;
    }

    p:first-child img {
      margin-top: 0;
    }

    figure {
      img {
        margin-top: 0;
        margin-bottom: 0;
        max-width: 100%;
      }

      figcaption {
        font-style: italic;
        font-size: 0.9em;
      }
    }

    figure.figure-shadow {
      img {
        box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.03);
      }
    }
  }

  .uk-navbar-dropdown-nav {
    font-size: 0.85rem;
    font-weight: 400;
    letter-spacing: 0.01em;

    > li > a {
      padding: 8px 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      &::before {
        content: '';
        /*display: inline-block;
          position: relative;
          top: -4px;
          left: 0;
          margin: 0 14px 0 0;
          width: 15px;
          height: 1px;
          background-color: red;*/

        display: inline-block;
        border: solid $global-primary-background;
        border-width: 0 2px 2px 0;
        padding: 2px;
        margin: 0 1em 3px 0px;
        transition: all 0.2s ease;
        transform: rotate(-45deg);
      }
    }
  }

  .uk-navbar-dropdown-grid > div {
    position: relative;
    h4 {
      font-size: 1.5rem;
    }
  }
  .uk-navbar-dropdown-grid > div:not(.uk-first-column)::before {
    /*content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: $navbar-dropdown-grid-gutter-horizontal / 2;
    border-left: 1px solid $global-border;*/
  }

  // Networking slider block

  /* Our wrapper */
  .uk-networking-slider-wrapper {
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    border: solid 1px $global-border;
    padding-top: 48.5%;
  }

  .uk-networking-slider-responsive-frame {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }

  /* Our image information */
  .uk-networking-slider-before {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-color: $global-inverse-color;
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    overflow: hidden;
  }

  .uk-networking-slider-after {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-color: white;
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    overflow: hidden;
    &::selection {
      background: transparent;
    }
  }

  .uk-networking-slider-content-image {
    max-width: unset;
  }

  .uk-networking-slider-after {
    width: 125px;
  }

  .uk-networking-slider-scroller {
    width: 50px;
    height: 50px;
    position: absolute;
    left: 100px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    background-color: transparent;
    opacity: 0.9;
    pointer-events: auto;
    cursor: pointer;
  }

  .uk-networking-slider-scroller:hover {
    opacity: 1;
  }

  .uk-networking-slider-scrolling {
    pointer-events: none;
    opacity: 1;
  }

  .uk-networking-slider-scroller__thumb {
    width: 100%;
    height: 100%;
    padding: 5px;
  }

  .uk-networking-slider-scroller:before,
  .uk-networking-slider-scroller:after {
    content: ' ';
    display: block;
    width: 5px;
    height: 9999px;
    position: absolute;
    left: 50%;
    margin-left: -2.5px;
    z-index: 30;
    transition: 0.1s;
  }
  .uk-networking-slider-scroller:before {
    top: 100%;
  }
  .uk-networking-slider-scroller:after {
    bottom: 100%;
  }

  /* If you want to cahnge the colors, make sure you change the fill in the svgs to match */
  .uk-networking-slider-scroller {
    border: 5px solid $global-dark-background;
  }
  .uk-networking-slider-scroller:before,
  .uk-networking-slider-scroller:after {
    background: $global-dark-background;
  }

  #demo {
    .uk-modal-close-full {
      right: 17px;
      padding-right: 15px;
    }
  }

  .uk-contact-button {
    padding: 10px 16px 9px 16px;
    margin-top: 15px;
  }

  .uk-contact-grid {
    margin-left: -15px;
    .uk-grid-margin {
      margin-top: 15px;
    }
    > * {
      padding-left: 15px;
    }
  }

  .video-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
  }

  .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 4px solid #f4f4f4;
    border-radius: 12px;
  }

  .switcher-button {
    /* transition: .64s; */
  }

  .switcher-button:hover {
    color: $global-black-color;
    background-color: $global-light-color;
  }

  .en-button-default {
    background: none;
    padding: 12px 30px;
    margin: 2px;
    border-radius: 10px;
    /* min-width: 180px;
    font-size: .9em; */

    &.uk-active {
      background-color: $global-border;
      color: $global-inverse-color;
    }
  }

  @media (max-width: $breakpoint-medium) {
    .en-button-default {
      padding: 12px 20px;
      min-width: auto;
    }
  }

  .uk-switcher-content {
    background: $global-inverse-color;
    padding: 40px 30px;
    border-radius: 10px;
  }

  .uk-switcher-background {
    background-color: $global-inverse-color;
    border: 1px solid #E5E5E7;
    padding: 3px;
    border-radius: 14px;
    width: fit-content;
    margin: 0 auto 0 auto;
  }

  html.gdpr-cookie-notice-loaded::before {
    background: rgba(0, 0, 0, 0.4);
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1;
    position: fixed;
    content: '';

    opacity: 1;
    animation-name: fadeInCookieNoticeOverlay;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 400ms;
  }

  @keyframes fadeInCookieNoticeOverlay {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  // Faq
  .faq-section {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    &-title {
      text-align: left;
      min-width: 250px;
      max-width: 35%;

      @media (max-width: $breakpoint-medium) {
        min-width: unset;
        max-width: 100%;
        text-align: center;
      }
    }

    @media (max-width: $breakpoint-medium) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .faq-list {
    width: 60%;
    margin-top: 0;

    @media (max-width: $breakpoint-medium) {
      width: 100%;
    }

    > :first-child {
      border-top: 1px solid $global-gray-2-color;
    }

    &-item {
      margin: 0 !important;
      padding: 2rem;
      border-bottom: 1px solid $global-gray-2-color;
      max-width: 100%;

      &-title {
        color: $global-black-color !important;

        &:hover,
        &:active {
          color: $global-black-color !important;
        }

        &::before {
          width: 24px;
          height: 24px;
          background: url(../../uploads/Plus_Icon.svg) !important;
          background-position: 50% 50%;
          background-size: cover;
          background-repeat: no-repeat;
        }
      }

      &.uk-open &-title::before {
        width: 24px;
        height: 24px;
        background: url(../../uploads/Minus_Icon.svg) !important;
        background-position: 50% 50%;
        background-size: cover;
        background-repeat: no-repeat;
      }

      &-description {
        font-size: 1rem;
      }

      &.uk-open {
        margin: 0;
        background-color: $global-gray-1-color;
      }
    }
  }
}
