.custom-switch-button-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.custom-switch-button-container {
  display: flex;
  max-width: 100%;
  width: fit-content;
  flex-wrap: wrap;
  border-radius: 0.5rem;
  border: 1px solid #8770d9;
  border-radius: 4px;
}

.custom-switch-button {
  height: 42px;
  min-width: 222px;
  width: 100%;
  flex: 1;
  border: none;
  border-radius: 0;

  &:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  &:nth-child(2) {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

.custom-switch-button-small {
  min-width: 166px;
}

.custom-switch-button.uk-active {
  background-color: #8770d9;
  color: #ffffff;
}

.custom-switch-content {
  display: flex;
  overflow: hidden;
  height: 0 !important;
}
.custom-switch-content-is-active {
  // to make box-shadow visible
  overflow: visible;
  height: auto !important;
}
