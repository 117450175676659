
// Global
$global-font-family:                              'Inter', sans-serif;
$global-2xlarge-font-size:                        3rem;
$global-xlarge-font-size:                         2.375rem;
$global-large-font-size:                          1.875rem;
$global-medium-font-size:                         1.375rem;
$global-control-large-height:                     52px;
$global-border:                                 #8770D9;
$global-border-hover:                           #9CB6F5;
$global-light-color:                            #EBF2FE;
$global-inverse-color:                          #fff;
$global-inverse-1-color:                        #fafafa;
$global-inverse-2-color:                        #eee;
$inverse-global-color:                            $global-inverse-color;
$base-link-color:                                 $global-primary-background;
$base-link-hover-color:                           $global-primary-background;
$inverse-base-link-color:                         $global-inverse-color;
$inverse-base-link-hover-color:                   $inverse-global-color;
$global-primary-background-data:                  rgba($global-primary-background, 0.9999999);
$global-muted-color:                            #888;
$global-black-color:                            #000000;
$global-gray-1-color:                           #F2F2F3;
$global-gray-2-color:                           #DBDBDC;
$global-gray-4-color:                           #727274;
$global-gray-7-color:                           #444;

// Base
$base-heading-font-family:                        'Source Sans Pro', sans-serif;
$base-body-font-weight:                           400;
$base-heading-font-weight:                        700;
$base-strong-font-weight:                         700;
$base-em-color:                                   $global-color;

$base-blockquote-font-size:                      inherit;
$base-blockquote-line-height:                    1.9;
$base-blockquote-font-style:                     normal;
// $base-blockquote-margin-vertical:                $global-margin !default;
// $base-blockquote-footer-margin-top:              $global-small-margin !default;
// $base-blockquote-footer-font-size:               $global-small-font-size !default;
// $base-blockquote-footer-line-height:             1.5 !default;

// Accordion
$internal-accordion-open-image:                   "data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolyline fill='none' stroke='%23000' stroke-width='1.8' points='16 7 10 13 4 7' /%3E%3C/svg%3E";
$internal-accordion-close-image:                  "data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolyline fill='none' stroke='%23000' stroke-width='1.8' points='7 4 13 10 7 16' /%3E%3C/svg%3E";

// Aticle
$article-title-font-size-m:                       2.75rem;

// Button
$button-text-transform:                           none;
$button-default-color:                            $global-dark-background;
$button-large-font-size:                          1rem;
$button-default-border:                           $global-border;
$button-default-hover-border:                     $global-border-hover;
$button-default-active-border:                    darken($global-border, 30%);

$button-link-hover-text-decoration:               none;
$button-link-color:                               $global-dark-background;
$button-link-hover-color:                         $global-primary-background;
$inverse-button-link-color:                       $inverse-global-color;
$inverse-button-link-hover-color:                 rgba($global-inverse-color, 0.7);

$inverse-button-default-hover-background:         #fff;
$inverse-button-default-hover-color:              $global-dark-background;
$inverse-button-default-active-background:        #fff;
$inverse-button-default-active-color:             $global-dark-background;

$inverse-button-primary-background:               $global-primary-background;
$inverse-button-primary-color:                    #fff;
$inverse-button-primary-hover-background:         #9CB6F5;
$inverse-button-primary-hover-color:              #fff;
$inverse-button-primary-active-background:        darken($global-primary-background, 30%);
$inverse-button-primary-active-color:             #fff;

$inverse-button-secondary-background:               $global-secondary-background;
$inverse-button-secondary-color:                    #fff;
$inverse-button-secondary-hover-background:         darken($global-secondary-background, 5%);
$inverse-button-secondary-hover-color:              #fff;
$inverse-button-secondary-active-background:        $global-secondary-background;
$inverse-button-secondary-active-color:             #fff;

// Card
// $card-title-font-size:                            ;

// Container
$container-max-width:                             1224px;

// Grid
$grid-small-gutter-horizontal:                    25px;
$grid-small-gutter-vertical:                      25px;

$form-legend-font-size:														1.5rem;

// Label
$label-padding-vertical:                          2px;


// Nav
$nav-header-text-transform:                       none;
$nav-primary-item-color:                          $global-dark-background;
$nav-primary-item-hover-color:                    $global-primary-background;
$nav-primary-item-active-color:                   $global-primary-background;
$nav-primary-sublist-item-color:                  $global-dark-background;
$nav-primary-sublist-item-hover-color:            $global-primary-background;
$nav-primary-sublist-item-active-color:           $global-primary-background;
$nav-primary-item-line-height:                    1.8;
$internal-nav-parent-close-image: "data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2014%2014%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolyline%20fill%3D%22none%22%20stroke%3D%22#000%22%20stroke-width%3D%221.9%22%20points%3D%2210%201%204%207%2010%2013%22%20%2F%3E%0A%3C%2Fsvg%3E" !default;
$internal-nav-parent-open-image: "data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2014%2014%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolyline%20fill%3D%22none%22%20stroke%3D%22#000%22%20stroke-width%3D%221.9%22%20points%3D%221%204%207%2010%2013%204%22%20%2F%3E%0A%3C%2Fsvg%3E" !default;

$inverse-nav-default-item-color:                  $inverse-global-color;
$inverse-nav-default-item-hover-color:            $inverse-button-link-hover-color;
$inverse-nav-default-item-active-color:           $inverse-global-color;

$modal-title-font-size:                           2rem;

// Navbar
$navbar-nav-item-text-transform:                  none;
$navbar-nav-item-height:                          65px;
$navbar-nav-item-padding-horizontal:              10px;
$navbar-background:                               #fff;
$navbar-nav-item-color:                           $global-dark-background;
$navbar-nav-item-hover-color:                     $global-primary-background;
$navbar-nav-item-onclick-color:                   $global-dark-background;
$navbar-nav-item-active-color:                    $global-primary-background;
$navbar-dropdown-nav-item-color:                  $global-dark-background;
$navbar-dropdown-nav-item-hover-color:            $global-primary-background;
$navbar-dropdown-nav-item-active-color:           $global-primary-background;
$navbar-dropdown-width:                           230px;
$navbar-dropdown-margin:                          0;

$navbar-toggle-color:                             $global-dark-background;
$navbar-toggle-hover-color:                       $global-primary-background;

$inverse-navbar-nav-item-color:                   #fff;
$inverse-navbar-nav-item-hover-color:             #ddd;
$inverse-navbar-nav-item-onclick-color:           #fff;
$inverse-navbar-nav-item-active-color:            #fff;
$inverse-navbar-toggle-color:                     #fff;

$navbar-dropdown-grid-gutter-horizontal:          100px;

// Subnav
$subnav-item-text-transform:                      none;
$subnav-pill-item-background:                     #fff;
$subnav-pill-item-hover-background:               darken($global-muted-background, 1%);
// Text
$text-lead-font-size:                             1.25rem;
$text-lead-line-height:                           1.7;

$base-selection-background:                     #4C5C6D;

