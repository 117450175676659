.pricing-discount {
  position: absolute;
  right: 30px;
  bottom: -30px;
}

.pricing-cost-card {
  display: flex;
  flex-direction: column;
  background-color: $global-dark-background;

  max-width: 784px;

  @media (min-width: 780px) {
    border-radius: 8px 0px;
  }

  & > *:first-child {
    min-height: 316px;
  }
  * {
    color: #ffffff;
  }

  h1:after,
  .uk-h1:after {
    display: none;
  }

  .uk-first-column {
    display: flex;
    flex-direction: column;

    @media (min-width: $breakpoint-small) {
      padding-right: 16px;
    }
  }
}

.pricing-cost-card-header {
  margin-bottom: 40px;
}

.pricing-cost-wrapper {
  align-self: center;
  flex-direction: column;
}

.pricing-cost-container {
  padding-top: 15px;
  width: 220px;
}

.pricing-cost-currency {
  vertical-align: top;
  margin-top: -10px;
}

.pricing-cost-upscale {
  font-family: Source Sans Pro;
  font-weight: 700;
  font-size: 180px;
  line-height: 100px;
}

.pricing-advantages-list {
  display: grid;
  grid-template-columns: 1fr;

  @media (min-width: $breakpoint-small) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: $breakpoint-medium) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  column-gap: 16px;
  row-gap: 40px;
}

.pricing-advantages-list-element {
  display: flex;
}

.pricing-partner-container {
  display: flex;
  flex-direction: column-reverse;

  & > :first-child {
    margin-top: 24px;

    @media (min-width: $breakpoint-medium) {
      margin-top: 0;
      max-width: 380px;
    }
  }

  @media (min-width: $breakpoint-medium) {
    flex-direction: row;
  }
}
