.custom-input-label {
  padding-left: 2px;
  text-align: left;
  float: left;

  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
}

.custom-input {
  border-color: $global-gray-4-color;
  border-radius: 4px;
  height: 42px;
}
