/**
 * Syntax highlighting styles
 */

pre.highlight {
    background-color: $global-muted-background;;
    border: solid 1px darken($global-muted-background, 5%);
    padding: 8px 10px;
    border-radius: 5px;
}

.highlight .lineno {
    color: #B1B8C4
}

.highlight .c {
    color: #B1B8C4
}

.highlight .err {
    color: #5A6575
}

.highlight .g {
    color: #5A6575
}

.highlight .k {
    color: #25BEA1
}

.highlight .l {
    color: #5A6575
}

.highlight .n {
    color: #5A6575
}

.highlight .o {
    color: #25BEA1
}

.highlight .x {
    color: #FBBC09
}

.highlight .p {
    color: #25BEA1
}

.highlight .cm {
    color: #B1B8C4
}

.highlight .cp {
    color: #25BEA1
}

.highlight .c1 {
    color: #B1B8C4
}

.highlight .cs {
    color: #25BEA1
}

.highlight .gd {
    color: #E56134
}

.highlight .ge {
    color: #5A6575;
    font-style: italic
}

.highlight .gr {
    color: #9961C9
}

.highlight .gh {
    color: #FBBC09
}

.highlight .gi {
    color: #25BEA1
}

.highlight .go {
    color: #5A6575
}

.highlight .gp {
    color: #FBBC09
}

.highlight .gs {
    color: #5A6575;
    font-weight: bold
}

.highlight .gu {
    color: #FBBC09
}

.highlight .gt {
    color: #5A6575
}

.highlight .kc {
    color: #FBBC09
}

.highlight .kd {
    color: #11A0F3
}

.highlight .kn {
    color: #25BEA1
}

.highlight .kp {
    color: #25BEA1
}

.highlight .kr {
    color: #11A0F3
}

.highlight .kt {
    color: #9961C9
}

.highlight .ld {
    color: #5A6575
}

.highlight .m {
    color: #E56134
}

.highlight .s {
    color: #E56134
}

.highlight .na {
    color: #5A6575
}

.highlight .nb {
    color: #5A6575
}

.highlight .nc {
    color: #11A0F3
}

.highlight .no {
    color: #5A6575
}

.highlight .nd {
    color: #11A0F3
}

.highlight .ni {
    color: #FBBC09
}

.highlight .ne {
    color: #FBBC09
}

.highlight .nf {
    color: #11A0F3
}

.highlight .nl {
    color: #5A6575
}

.highlight .nn {
    color: #5A6575
}

.highlight .nx {
    color: #5A6575
}

.highlight .py {
    color: #5A6575
}

.highlight .nt {
    color: #11A0F3
}

.highlight .nv {
    color: #5A6575
}

.highlight .ow {
    color: #25BEA1
}

.highlight .w {
    color: #5A6575
}

.highlight .mf {
    color: #E56134
}

.highlight .mh {
    color: #E56134
}

.highlight .mi {
    color: #E56134
}

.highlight .mo {
    color: #E56134
}

.highlight .sb {
    color: #B1B8C4
}

.highlight .sc {
    color: #E56134
}

.highlight .sd {
    color: #5A6575
}

.highlight .s2 {
    color: #E56134
}

.highlight .se {
    color: #FBBC09
}

.highlight .sh {
    color: #5A6575
}

.highlight .si {
    color: #E56134
}

.highlight .sx {
    color: #E56134
}

.highlight .sr {
    color: #9961C9
}

.highlight .s1 {
    color: #E56134
}

.highlight .ss {
    color: #E56134
}

.highlight .bp {
    color: #11A0F3
}

.highlight .vc {
    color: #11A0F3
}

.highlight .vg {
    color: #11A0F3
}

.highlight .vi {
    color: #11A0F3
}

.highlight .il {
    color: #E56134
}

.highlight {
    .rouge-table {
        margin-bottom: 0;
        pre {
            margin-bottom: 0;
            background-color: transparent;
            border: none;
        }
        .lineno {
            padding: 0 10px 0 0;
              -webkit-touch-callout: none;
                -webkit-user-select: none;
                 -khtml-user-select: none; 
                   -moz-user-select: none; 
                    -ms-user-select: none;
                        user-select: none;
        }
        .rouge-code pre {
            padding: 0
        }
        td {
            padding: 0
        }
    }
}