.custom-bottom-line {
    width: 100px;
    height: 8px;
  
    position: absolute;
    bottom: 0;
    left: 0;
  
    background: $global-border;
    border-radius: 0px 8px 8px 0px;
  }