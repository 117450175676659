.partners-section {
  padding-top: 100px;
  padding-bottom: 100px;
}

.partners-switch-grid {
  flex-direction: column;

  @media (min-width: $breakpoint-medium) {
    flex-direction: row;
  }
}
.partners-fieldset {
  max-width: 100%;
  min-width: 0;
}

.partners-select {
  height: 42px !important;
}

.partner-left {
  padding-right: 0;

  @media (min-width: $breakpoint-medium) {
    padding-right: $global-medium-margin;
  }

  @media (min-width: $breakpoint-large) {
    padding-right: $global-large-margin;
  }
}

.partners-left-description {
  font-size: 1rem;
  line-height: 1.375rem;
}

.partners-right-header {
  margin-bottom: 24px;
}

.partners-right-description-1 {
  margin-bottom: 16px;
}

.partners-right-description-2 {
  margin-bottom: 22px;
}

.partners-right-input-grid {
  margin-bottom: $global-medium-margin;
}

.partners-right-checkbox-container {
  margin-bottom: 24px;
}

.partners-form-grid {
  margin-left: -16px;
}
.partners-form-grid > * {
  padding-left: 16px;
}
